import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { useMutation } from '@apollo/client'

import useRouteMatch from 'lib/hooks/useRouteMatch'

import { isPrivateRoute, isServer, matchPath, setCookieUserLocale } from 'lib/utils'
import { sift } from 'lib/utils/sift'
import { buildPath, getError, logError } from 'lib/utils'
import { getStorageItem, removeStorageItem } from 'lib/utils/web-storage'
import {
  getToken,
  setToken,
  clearToken,
  setRefreshToken,
  setDsUserId,
  getDsUserId,
  getUserStorageData,
  setUserStorageData,
} from 'lib/utils/auth'

import {
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
  WISHLIST_ITEMS_ROUTE,
} from 'lib/constants/routes'
import { NEW_ACCOUNT_CREATED_FLAG, NEW_USER_CONSENT_MODAL } from 'lib/constants'

import { LOGOUT_MUTATION } from 'gql/auth'

interface UserResponse {
  accessToken?: string
  refreshToken?: string
  customer: Customer | null
}

type OpenModalFn = ({
  screen,
  redirectUrl,
  custom,
}: {
  screen: PrimaryAuthScreens
  redirectUrl?: string
  custom?: Record<string, string> | null
}) => void
export interface IAuthContext {
  isLoggedIn: boolean
  user: Customer | null
  handleUserResponse: (response: UserResponse) => Customer | null
  getPostAuthRedirectUrl: () => string
  logout(): void
  authInProgress: boolean
  setAuthInProgress: (value: boolean) => void
  setUserPhoneAsVerified: ({ isdCode, contactNumber }: { isdCode?: string; contactNumber?: string }) => void
  accountCreatedToast: {
    isOpen: boolean
    close: () => void
    show: () => void
  }
  consentModal: {
    isOpen: boolean
    open: () => void
    close: () => void
  }
  authModal: {
    custom: Record<string, string> | null
    isOpen: boolean
    initialScreen: PrimaryAuthScreens // the intial screen when modal opens. the subsequent screen change once the modal is opened will not update this property
    redirectUrl: string
    open: OpenModalFn
    close: () => void
  }
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined)
AuthContext.displayName = 'AuthContext'

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Customer | null>(null)
  const router = useRouter()

  const setUserPhoneAsVerified = useCallback(
    ({ isdCode, contactNumber }: { isdCode?: string; contactNumber?: string } = {}) => {
      setUser((curr) => {
        if (!curr) return curr

        const newUser = { ...curr, isPhoneVerified: true }

        if (isdCode && contactNumber) {
          newUser.isdCode = isdCode
          newUser.contactNumber = contactNumber
        }
        setUserStorageData(newUser)

        return newUser
      })
    },
    [setUser]
  )

  const [authModalData, setAuthModalData] = useState<{
    isOpen: boolean
    custom: Record<string, string> | null
    initialScreen: PrimaryAuthScreens
    redirectUrl: string
  }>({
    isOpen: false,
    custom: null,
    initialScreen: 'login',
    redirectUrl: buildPath(HOME_ROUTE),
  })
  const [isConsentModalOpen, setIsConsentModalOpen] = useState<boolean>(() => {
    const isNewUserWithoutConsent = getStorageItem(NEW_USER_CONSENT_MODAL)
    // adding timeout to make sure that the account created flag is not getting removed
    // if the current page reloads immediately after rendering once
    setTimeout?.(() => {
      removeStorageItem(NEW_USER_CONSENT_MODAL)
    }, 1000)

    return isNewUserWithoutConsent && JSON.parse(isNewUserWithoutConsent)
  })

  const [isAccCreatedToastOpen, setIsAccCreatedToastOpen] = useState<boolean>(() => {
    const isNewAccount = getStorageItem(NEW_ACCOUNT_CREATED_FLAG)
    // adding timeout to make sure that the account created flag is not getting removed
    // if the current page reloads immediately after rendering once
    setTimeout?.(() => {
      removeStorageItem(NEW_ACCOUNT_CREATED_FLAG)
    }, 1000)

    return isNewAccount && JSON.parse(isNewAccount)
  })

  const isLoggedIn = useMemo(() => {
    return Boolean(getToken())
    // make sure isLoggedIn is getting updated on user change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const [authInProgress, setAuthInProgress] = useState<boolean>(false)

  const [doLogout] = useMutation(LOGOUT_MUTATION.mutation)

  // only use this function for logged in users
  const handleUserResponse = useCallback(({ customer, accessToken, refreshToken }: UserResponse) => {
    const { dsUserId } = customer || {}
    sift.setUserId(customer?.customerId || '')

    if (dsUserId) {
      setDsUserId(dsUserId)
      window.Sentry?.configureScope((scope: any) => {
        scope.setUser({ id: dsUserId })
      })
    }
    if (accessToken) setToken(accessToken)
    if (refreshToken) setRefreshToken(refreshToken)
    setUser(customer)
    setUserStorageData(customer)
    return customer
  }, [])

  const logout = useCallback(async () => {
    try {
      const { data } = await doLogout()
      const error = getError(data[LOGOUT_MUTATION.name])
      if (error?.code && error.code !== 200) {
        logError(new Error(error?.errorMessage))
      }
    } catch (error) {
      logError(error)
    } finally {
      setUser(null)
      clearToken()
      setCookieUserLocale('', 0)
      setUserStorageData(null)

      if (isPrivateRoute(router.asPath) || matchPath(router.asPath, WISHLIST_ITEMS_ROUTE)) {
        window.location.replace('/')
      } else {
        window.location.reload()
      }
    }
  }, [doLogout, router.asPath])

  const isLoginRoute = Boolean(useRouteMatch(LOGIN_ROUTE))
  const isSignupROute = Boolean(useRouteMatch(SIGNUP_ROUTE))

  const openAuthModal = useCallback<OpenModalFn>(
    ({ screen, redirectUrl, custom }) => {
      // if user is trying to open modal from auth route, do not open modal. Use route to show the auth page instead
      if (isLoginRoute || isSignupROute) {
        const searchParams = qs.parse(window.location.search)
        if (isLoginRoute && screen === 'signup') {
          router.replace(buildPath(SIGNUP_ROUTE, {}, searchParams))
        } else if (isSignupROute && screen === 'login') {
          router.replace(buildPath(LOGIN_ROUTE, {}, searchParams))
        }
        return
      }
      setAuthModalData({
        initialScreen: screen,
        custom: custom ?? null,
        isOpen: true,
        redirectUrl: redirectUrl || router.asPath || buildPath(HOME_ROUTE),
      })
    },
    [setAuthModalData, isLoginRoute, isSignupROute, router]
  )

  const closeAuthModal = useCallback(() => {
    setAuthModalData((curr) => ({ ...curr, isOpen: false }))
  }, [setAuthModalData])

  const getPostAuthRedirectUrl = useCallback<() => string>(() => {
    const searchParams = isServer ? router.query : qs.parse(window.location.search)

    // make sure we do not redirecting user to forgot password or reset password path in any scenario
    let redirect
    if (
      searchParams.redirectUrl &&
      typeof searchParams.redirectUrl === 'string' &&
      !matchPath(searchParams.redirectUrl, FORGOT_PASSWORD_ROUTE) &&
      !matchPath(searchParams.redirectUrl, RESET_PASSWORD_ROUTE)
    ) {
      redirect = searchParams.redirectUrl // redirect url query param has priority over global authmodal redirect url state
    } else if (
      authModalData.redirectUrl &&
      !matchPath(authModalData.redirectUrl, FORGOT_PASSWORD_ROUTE) &&
      !matchPath(authModalData.redirectUrl, RESET_PASSWORD_ROUTE)
    ) {
      redirect = authModalData.redirectUrl
    } else {
      redirect = buildPath(HOME_ROUTE)
    }

    return redirect
  }, [authModalData.redirectUrl, router.query])

  const authModal = useMemo(
    () => ({
      ...authModalData,
      open: openAuthModal,
      close: closeAuthModal,
    }),
    [authModalData, openAuthModal, closeAuthModal]
  )

  const consentModal = useMemo(
    () => ({
      isOpen: isConsentModalOpen,
      open: () => setIsConsentModalOpen(true),
      close: () => setIsConsentModalOpen(false),
    }),
    [setIsConsentModalOpen, isConsentModalOpen]
  )

  const accountCreatedToast = useMemo(
    () => ({
      isOpen: isAccCreatedToastOpen,
      close: () => setIsAccCreatedToastOpen(false),
      show: () => setIsAccCreatedToastOpen(true),
    }),
    [setIsAccCreatedToastOpen, isAccCreatedToastOpen]
  )

  useEffect(() => {
    // populate localStorage value to Context
    const data: { user: Customer } = getUserStorageData()
    if (data) {
      setUser(data.user)
      sift.setUserId(data?.user?.customerId || '')
    }
    window.Sentry?.configureScope((scope: any) => {
      scope.setUser({ id: getDsUserId() })
    })
  }, [])

  const value: IAuthContext = useMemo(
    () => ({
      user,
      isLoggedIn,
      handleUserResponse,
      logout,
      authModal,
      consentModal,
      accountCreatedToast,
      getPostAuthRedirectUrl,
      authInProgress,
      setAuthInProgress,
      setUserPhoneAsVerified,
    }),
    [
      user,
      isLoggedIn,
      handleUserResponse,
      logout,
      authModal,
      consentModal,
      accountCreatedToast,
      getPostAuthRedirectUrl,
      authInProgress,
      setAuthInProgress,
      setUserPhoneAsVerified,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }
