import { forwardRef, useMemo } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'

import { Portal } from 'components/portal'

import { useModal } from 'lib/hooks/useModal'

import Confetti from 'brand-assets/illustrations/logo/confetti.svg'

import { ModalFooter, ModalHeader, DrawerDialogProps } from './types'

import s from './styles.module.scss'

const Header = ({
  header,
  hasCloseButton,
  hasBackButton,
  backgroundSVG,
  headerVariant = 'default',
  onClose,
  onBackClick,
}: ModalHeader) => {
  return (
    <div className={cn(s.header, s[headerVariant], { [s.hasSVG]: !!backgroundSVG })}>
      {hasBackButton && (
        <Icon
          className="cursor-pointer mr-2"
          name="caret-left"
          size="medium"
          onClick={hasBackButton && onBackClick ? onBackClick : onClose}
        />
      )}
      {backgroundSVG && <span className={s.svgBg}>{backgroundSVG}</span>}
      <span className={s.title}>{header}</span>
      {hasCloseButton && (
        <Icon
          className={cn('cursor-pointer', s.closeIcon, { [s.closeBtn]: backgroundSVG })}
          name="delete-x"
          size="medium"
          onClick={onClose}
        />
      )}
    </div>
  )
}

const Footer = ({
  customFooter,
  primaryButtonText,
  secondaryButtonText,
  disablePrimaryButton,
  disableSecondaryButton,
  isPrimaryButtonFluid,
  isSecondaryButtonFluid,
  secondaryButtonVariant,
  primaryButtonFixWidth,
  buttonGap,
  buttonsAlignment = 'default',
  buttonAsSubmit = 'none',
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}: ModalFooter) => {
  if (!primaryButtonText && !customFooter) return null

  const hasCustomFooter = !!customFooter
  const hasSingleButton = !hasCustomFooter && !secondaryButtonText
  const isCenterButton = hasSingleButton && !isPrimaryButtonFluid && buttonsAlignment === 'default'
  const gapBetweenButton =
    buttonGap && buttonsAlignment === 'vertical'
      ? { marginTop: buttonGap }
      : buttonGap
      ? { marginRight: buttonGap }
      : undefined

  return (
    <div
      className={cn(
        s.footer,
        {
          [s.hasSingleButton]: hasSingleButton,
          [s.alignCenterButton]: isCenterButton,
        },
        s[buttonsAlignment]
      )}
    >
      {hasCustomFooter ? (
        customFooter
      ) : (
        <>
          {secondaryButtonText && (
            <Button
              variant={secondaryButtonVariant || 'link-tertiary'}
              onClick={onSecondaryButtonClick}
              disabled={disableSecondaryButton}
              fluid={isSecondaryButtonFluid}
              style={gapBetweenButton}
              {...(buttonAsSubmit === 'secondary' ? { type: 'submit' } : {})}
            >
              {secondaryButtonText}
            </Button>
          )}

          <Button
            onClick={onPrimaryButtonClick}
            fluid={isPrimaryButtonFluid}
            disabled={disablePrimaryButton}
            style={primaryButtonFixWidth ? { width: primaryButtonFixWidth } : undefined}
            {...(buttonAsSubmit === 'primary' ? { type: 'submit' } : {})}
          >
            {primaryButtonText}
          </Button>
        </>
      )}
    </div>
  )
}

const DrawerDialog = forwardRef<any, DrawerDialogProps>(
  (
    {
      children,
      variant,
      header,
      headerVariant,
      center,
      onClose,
      onClickOutside,
      onEscKeyPress,
      onBackClick,
      open = false,
      hasCloseButton = true,
      hasBackButton = false,
      asBottomSheet = false,
      fixedHeight = false,
      fixedWidth = false,
      autoMinHeight = false,
      animation,
      bringToFront = false,
      ignorePageScroll = false,
      sideSpace = false,
      noBodyPadding,
      colorVariant = 'primary',
      ...footerProps
    },
    ref
  ) => {
    const { overlayRef, containerRef, activateAnimation, isOpen } = useModal({
      open,
      ignorePageScroll,
      onClickOutside,
      onEscKeyPress,
    })
    const hasFooter = !!footerProps.primaryButtonText || !!footerProps.customFooter

    const animationClassName = useMemo(() => {
      if (animation === 'slideUp') return s.slideUp
      return s.fade
    }, [animation])

    return (
      <Portal open={isOpen}>
        <div
          className={cn(
            s.popup,
            asBottomSheet ? s.asBottomSheet : s.asDialog,
            {
              [s.animation]: activateAnimation,
              [s.bringToFront]: bringToFront,
            },
            s[colorVariant]
          )}
          id="data-dialog"
          ref={overlayRef}
        >
          <div ref={containerRef} className={s['flex-center']}>
            <div
              className={cn(s.container, s[variant], animationClassName, {
                [s.hasFooter]: hasFooter,
                [s.fixedHeight]: fixedHeight,
                [s.fixedWidth]: fixedWidth,
                [s.noMinHeight]: fixedHeight || autoMinHeight,
                [s.center]: center,
                [s.sideSpace]: sideSpace,
              })}
              ref={ref}
            >
              <Header
                backgroundSVG={colorVariant === 'promo' ? <Confetti /> : undefined}
                header={header}
                hasCloseButton={hasCloseButton}
                hasBackButton={hasBackButton}
                onClose={onClose}
                onBackClick={onBackClick}
                headerVariant={headerVariant}
              />
              <div className={cn(s.body, noBodyPadding && s.noPadding)}>{children}</div>
              <Footer {...footerProps} />
            </div>
          </div>
        </div>
      </Portal>
    )
  }
)
DrawerDialog.displayName = 'DrawerDialog'

export { DrawerDialog }
