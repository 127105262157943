import { memo, useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'

import { Modal } from 'components/responsive-modal'
import { useDestinationSwitcher } from 'components/destination-switcher/hooks'

import { useGlobalContext } from 'lib/context/global-context'
import { useAppData } from 'lib/context/app-data-context'
import { useDestinationByRegionContext } from 'lib/context/destination-by-region-context'

import { EVENTS } from 'lib/constants/events'

import { RecentlyViewed } from './recently-viewed'
import { ExploreDestinations } from './explore-destinations'
import { SearchResults } from './search-results'
import { DestinationModalHeader } from './destination-modal-header'
import type { ItemClickFn } from './types'

import s from './styles.module.scss'

type DestinationModalProps = {
  open: boolean
  closeModal: () => void
  trackEvent: TrackEventType
  defaultLocaleValues?: Record<string, string>
  onDestinationChange?: (id: string) => void
  onCountryChange?: (id: string) => void
  shouldAutoFocus?: boolean
  playAnimation?: boolean
  shouldRedirectAfterSelect?: boolean
  defaultSearchQuery?: string
  selectedTag?: string
}

const DestinationModalBase = ({
  open,
  closeModal,
  defaultLocaleValues,
  onDestinationChange,
  onCountryChange,
  shouldRedirectAfterSelect = true,
  shouldAutoFocus = true,
  playAnimation = true,
  defaultSearchQuery,
  selectedTag,
  trackEvent,
}: DestinationModalProps) => {
  const { isMobileView } = useGlobalContext()
  const { activityLog } = useAppData()
  const { destinationInfo, searchText, debouncedSearchText, searchCountryDestination } =
    useDestinationSwitcher({
      defaultSearchQuery,
      defaultLocaleValues,
      debounceValue: 500,
    })

  const {
    data: destinationsByRegion,
    page: destinationRegionPage,
    loading: destinationRegionLoading,
    initialLoading: destinationRegionInitialLoading,
    fetchMore: fetchMoreDestinationsByRegion,
  } = useDestinationByRegionContext()

  const inputRef = useRef<HTMLInputElement>()
  const modalBodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!open) searchCountryDestination('')
    else if (shouldAutoFocus) inputRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: 0 })
    }
  }, [searchText])

  const onItemClick: ItemClickFn = (
    id,
    { isCountry, source, name, parentCountryName, code, countryCode, id: countryId }
  ) => {
    const valueKey = isCountry ? 'destinationId' : 'countryId'
    const eventAttributeId = isCountry ? EVENTS.COUNTRY : EVENTS.DESTINATION
    trackEvent({
      attributeId: eventAttributeId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.OPTION,
      attributeValue: { [valueKey]: id, eventSource: source },
    })

    activityLog.addItem({
      destinationCountry: {
        id: countryId || id,
        name,
        parentCountryName,
        code,
        countryCode,
      },
    })
    closeModal()

    if (isCountry) {
      onCountryChange?.(id)
    } else {
      onDestinationChange?.(id)
    }
  }

  const modalAnimation = useMemo(() => {
    if (!playAnimation) return null

    if (isMobileView) {
      if (shouldAutoFocus) return null

      return 'slideUp'
    }

    return 'fade'
  }, [playAnimation, isMobileView, shouldAutoFocus])

  return (
    <Modal
      open={open}
      animation={modalAnimation}
      onCloseButtonClick={closeModal}
      onClickOutside={closeModal}
      contentClassName={s.container}
      fullScreen={isMobileView}
      hideCloseButton={isMobileView}
    >
      <div className={s.modal}>
        <DestinationModalHeader
          inputRef={inputRef}
          onSearchChange={searchCountryDestination}
          searchText={searchText}
          closeModal={closeModal}
        />
        <div className={cn(s.modalBody, s.tall)} ref={modalBodyRef}>
          <div className={s.modalBodyContent}>
            {!debouncedSearchText ? (
              <>
                <RecentlyViewed
                  onItemClick={onItemClick}
                  shouldRedirectAfterSelect={shouldRedirectAfterSelect}
                />
                <ExploreDestinations
                  onItemClick={onItemClick}
                  shouldRedirectAfterSelect={shouldRedirectAfterSelect}
                  destinationsByRegion={destinationsByRegion}
                  fetchMoreDestinationsByRegion={fetchMoreDestinationsByRegion}
                  isLoading={destinationRegionLoading}
                  initialLoading={destinationRegionInitialLoading}
                  currentPage={destinationRegionPage}
                  selectedTag={selectedTag}
                />
              </>
            ) : (
              <SearchResults
                countryAndDestinations={destinationInfo}
                onItemClick={onItemClick}
                shouldRedirectAfterSelect={shouldRedirectAfterSelect}
              />
            )}
          </div>
          {!isMobileView && <div className={s.modalBodyPlaceholder} />}
        </div>
      </div>
    </Modal>
  )
}

export const DestinationModal = memo(DestinationModalBase)
