export const bookingProductFields = `
  intentId
  bookingId
  productName
  productId
  productUri
  destinationId
  optionName
  optionId
  cancellationType
  confirmationType
  confirmationTypeText
  confirmationWindow
  mediaData
  redemptionInstructions
  cancellationPolicy {
    callout
    cancellationWindows {
      cancelBy
      cancelByValues
      refundPercentage
      isEligible
    }
  }
`
export const bookingProductUnitFields = `
  unitName
  price
  quantity
  grossTotal
`

export const amountFields = `
  total
  subTotal
  discount
  currency
  kfMiles
`

export const selectorMetaDataFields = `
  label
  key
  value
`

export const bookingIntentFields = `
  intentId
  product {
    ${bookingProductFields}
  }
  productUnits {
    ${bookingProductUnitFields}
  }
  amount {
    ${amountFields}
  }
  promoCode
  promoTitle
  pnrLastName
  pnrRef
  selectorMetaData {
    ${selectorMetaDataFields}
  }
  activityDate
  loyaltyRewards
  kfmilesBurnValue
  kfmilesMinimumValue
  stepper
  bookingFormConfig
  bookingFormFields
`

export const bookingUserInfoFields = `
  firstName
  lastName
  email
  isdCode
  contactNumber
  krisflyerNumber
  additionalNotes
  status
  dsUserId
  pnrRef
  pnrLastName
`

export const bookingFields = `
  bookingId
  customerId
  state
  bookingHeader
  bookingSubHeader
  bookingStatusHeader
  bookingStatusSubHeader
  bookingDisclaimerText
  bookingDisclaimerLabel
  redemptionType
  specialNotes
  availableMiles
  promoCallout
  product {
    ${bookingProductFields}
    categories
    subCategories
  }
  productUnits {
    ${bookingProductUnitFields}
  }
  selectorMetaData {
    ${selectorMetaDataFields}
  }
  userInfo {
    ${bookingUserInfoFields}
  }
  amount {
    ${amountFields}
    cindexed
    amountSgd
    normalizedAmount
  }
  payment {
    transactionId
    failedPaymentMessage
    currency
    paymentState
    paymentOptions {
      optionName
      isDefault
      isEnabled
      optionMetaData
      message
    }
  }
  activityDate
  promoCode
  loyaltyRewards
  kfmilesBurnValue
  kfmilesMinimumValue
  stepper
  bookingFormFields
  isRegisteredUser
  expiresIn
  bc
`

export const customerFields = `
  dsUserId
  email
  firstName
  lastName
  status
  isdCode
  isEmailVerified
  isPhoneVerified
  contactNumber
  customerId
  krisflyerNumber
  marketingEmail
  waMarketingConsent
  reminderEmail
  waReminderConsent
  createdAt
  isPasswordSet
  wishlistCount
  countryOfResidence
  locale
  currency
`
export const destination = `
  destinationId
  name
  countryId
  countryName
  code
`
export const PelagoError = `
  ... on PelagoError {
    errorHeader
    errorMessage
    code
    errorDetails
  }
`

export const PelagoListError = `
  ... on PelagoListError {
    errorMessage
    code
  }
`

export const productCardFields = `
  thumbnail
  productSubcategories {
    categoryId
  }
  productName
  shortDescription
  currency
  priceRangeFrom
  productId
  productUri
  destinationId
  destinationName
  productTags {
    tagId
  }
  nextAvailableDate
  reviewCount
  rating
  trackMeta
  strikeOffDiscountValue
  strikeOffDiscountType
  cancellationType
`

export const productFields = `
  productId
  productName
  productUri
  destinationId
  destination {
    destinationName
    destinationCode
  }
  destinationName
  kfEarnMilesPromoText
  kfmilesBurnValue
  maximumKfMiles
  kfmilesMinimumValue
  location
  productSubcategories {
    categoryId
  }
  shortDescription
  mediaData
  productTags {
    tagId
  }
  currency
  duration
  guideLanguages
  cancellationType
  cancellationTypeText
  hideKfMiles
  minGroupSize
  maxGroupSize
  openDateTicket
  collectPhysicalTicket
  confirmationType
  confirmationTypeText
  voucherType
  redemptionType
  priceRangeFrom
  priceRangeTo
  latitude
  longitude
  address
  availabilityStartDate
  availabilityEndDate
  availableDates
  content
  productMetaView
  status
  rating
  ratingDetails
  reviewCount
  trackMeta
  contentLang
  originalContentLang
  wishlistedCustomerCount
  strikeOffDiscountValue
  strikeOffDiscountType
  operatorName
  isPromoEnabled
  specialNotes
  redemptionInstructions
  reviewSummary
  ratingDistribution
`

export const searchProductCard = `
  productName
  shortDescription
  priceRangeFrom
  productId
  productUri
  destinationId
  productSubcategories {
    categoryId
  }
  productTags {
    tagId
  }
  reviewCount
  rating
  currency
  thumbnail
  destinationName
  countryName
  nextAvailableDate
  searchScore
`

export const wishlistFields = `
  wishlistName
  itemsCount
  items {
    productId
    dateCreated
    wishlistedDate
    product {
      ${productCardFields}
    }
  }
`

export const uploadImageFields = `
  uri
  fileName
`
