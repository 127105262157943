import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { useMutation } from '@apollo/client'
import posthog from 'posthog-js'

import useTranslation from 'lib/hooks/useTranslation'
import useTracking from 'lib/hooks/useTracking'
import useAuth from 'lib/hooks/useAuth'

import { getDsUserId } from 'lib/utils/auth'
import { getError, logError, matchPath } from 'lib/utils'
import { setStorageItem, getStorageItem, removeStorageItem } from 'lib/utils/web-storage'

import { BOOKING_DETAILS_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'
import { NEW_ACCOUNT_CREATED_FLAG, NEW_USER_CONSENT_MODAL } from 'lib/constants'

import { SOCIAL_AUTHORIZATION, SOCIAL_ONBOARDING_MUTATION } from 'gql/auth'

import useUserPreference from './useUserPreference'
import useRouteMatch from './useRouteMatch'

interface AuthResponse {
  authorizationUrl: string
  state: string
}

type AuthMode = 'login' | 'signup'

const PRE_SOCIAL_AUTH_LOCATION = '__pre-social-auth-location__'

// hook to store state in local storage before redirecting to facebook/google page
const usePreAuthLocation = () => {
  const { authModal } = useAuth()
  const isSignupRoute = Boolean(useRouteMatch(SIGNUP_ROUTE))
  const isLoginRoute = Boolean(useRouteMatch(LOGIN_ROUTE))

  const setPreAuthLocation = (authScreen: PrimaryAuthScreens = 'login'): void => {
    let redirectUrl
    if (authModal.isOpen) {
      redirectUrl = authModal.redirectUrl
    } else if (isSignupRoute || isLoginRoute) {
      const searchParams = qs.parse(window.location.search)
      redirectUrl = searchParams.redirectUrl
    }
    setStorageItem(PRE_SOCIAL_AUTH_LOCATION, JSON.stringify({ redirectUrl, authScreen }))
  }

  const clearPreAuthLocation = (): void => {
    removeStorageItem(PRE_SOCIAL_AUTH_LOCATION)
  }

  const getPreAuthLocation: () => { redirectUrl: string; authScreen: PrimaryAuthScreens } = () => {
    const preAuthLocation = getStorageItem(PRE_SOCIAL_AUTH_LOCATION)?.trim()
    return !!preAuthLocation && JSON.parse(preAuthLocation)
  }

  return { setPreAuthLocation, getPreAuthLocation, clearPreAuthLocation }
}

// hook to trigger social auth redirect
// once authorization process is completed from facebook/google side, they will redirect back to pelago
// this authScreen param value will be used to show the correct auth screen while onboarding is happening
const useSocialRedirect = ({ authScreen = 'login' }: { authScreen: PrimaryAuthScreens }) => {
  const { t } = useTranslation()
  const { setAuthInProgress } = useAuth()
  const { setPreAuthLocation } = usePreAuthLocation()
  const [socialAuthorization] = useMutation(SOCIAL_AUTHORIZATION.mutation)
  const [errorMsg, setErrMsg] = useState<string>('')

  const getAuthorizationUrl = async (oauthServer: OauthProvider): Promise<string | null> => {
    try {
      setErrMsg('')
      const response = await socialAuthorization({
        variables: { oauthServer },
      })
      const data: AuthResponse = response.data[SOCIAL_AUTHORIZATION.name]
      const error = getError(data)
      if (error.code >= 400) {
        throw new Error(error.errorMessage)
      }
      const { authorizationUrl, state } = data
      const completeAuthUrl = qs.stringifyUrl({ url: authorizationUrl, query: { state } })

      return completeAuthUrl
    } catch (error) {
      logError(error)
      setErrMsg(t('msg.sorrySomethingWentWrong'))
      return null
    }
  }

  const goToSocialAuthUrl = async (provider: OauthProvider) => {
    setAuthInProgress(true)

    const authUrl = await getAuthorizationUrl(provider)
    if (!authUrl) {
      setAuthInProgress(false)
      return
    }
    // store current location in store before redirecting
    setPreAuthLocation(authScreen)
    window.location.href = authUrl
  }

  return { goToSocialAuthUrl, error: errorMsg }
}

const getAuthMode = (path: string | undefined): AuthMode => {
  if (!path) return 'login'

  const isLogin = matchPath(path, LOGIN_ROUTE)
  return isLogin ? 'login' : 'signup'
}

// hook to handle the onboarding process after social auth redirect
const useSocialOnboard = (pageViewedRequirement?: () => boolean) => {
  const AUTH_LOGIN = 'loginType',
    AUTH_SIGNUP = 'signupType'
  const router = useRouter()
  const { t } = useTranslation()
  const [errorMsg, setErrMsg] = useState<string>('')
  const { applyUserPreferences } = useUserPreference()

  const [socialOnboard, { loading }] = useMutation(SOCIAL_ONBOARDING_MUTATION.mutation)
  const { handleUserResponse, setAuthInProgress, getPostAuthRedirectUrl } = useAuth()

  useEffect(() => {
    setAuthInProgress(loading)
  }, [loading, setAuthInProgress])

  const redirectUrl = getPostAuthRedirectUrl()

  const authMode = getAuthMode(router.pathname)
  const isLogin = authMode === 'login'

  // similar track event from Auth page with additional params specific to social auth
  const authAction: AuthAction = isLogin ? AUTH_LOGIN : AUTH_SIGNUP
  const { trackEvent } = useTracking({
    pageName: isLogin ? EVENTS.LOGIN : EVENTS.SIGNUP,
    pageMainAttributeType: 'auth',
    pageViewedDeps: [isLogin],
    pageViewedRequirement,
    additionalParams: {
      source: redirectUrl,
      attributeId: isLogin ? EVENTS.USER_LOGGED_IN : EVENTS.USER_SIGNED_UP,
      eventType: EVENTS.TYPE.EXPOSURE,
      isInteractionEvent: true,
    },
  })

  const getAuthAction = (provider: OauthProvider, isAccountCreate: boolean) => {
    return provider === 'GOOGLE_ONETAP' ? (isAccountCreate ? AUTH_SIGNUP : AUTH_LOGIN) : authAction
  }

  const getPageAndAttributeName = (provider: OauthProvider, isAccountCreate: boolean) => {
    if (provider !== 'GOOGLE_ONETAP') return null
    return {
      pageName: isAccountCreate ? EVENTS.SIGNUP : EVENTS.LOGIN,
      attributeId: isAccountCreate ? EVENTS.USER_SIGNED_UP : EVENTS.USER_LOGGED_IN,
    }
  }

  const trackSuccessEvent = (provider: OauthProvider, customerId: string, isAccountCreate: boolean) => {
    trackEvent({
      attributeType: EVENTS.ATTRIBUTES_TYPE.SUCCESS,
      attributeValue: {
        [getAuthAction(provider, isAccountCreate)]: provider?.toLowerCase(),
      },
      customerId,
      ...getPageAndAttributeName(provider, isAccountCreate),
    })
    const dataLayerEventName =
      !isLogin || (provider === 'GOOGLE_ONETAP' && isAccountCreate)
        ? DATA_LAYER_EVENT.REGISTRATION_COMPLETE
        : DATA_LAYER_EVENT.LOGIN_COMPLETE

    const payload = {
      [authAction]: provider.toUpperCase(),
      customerId,
    }
    // note: below same exist in AuthFlow also (in case any change in future)
    window?.dataLayer?.push?.({
      event: dataLayerEventName,
      ...payload,
    })
    posthog.capture(dataLayerEventName, payload)
  }
  const trackErrorEvent = (provider: OauthProvider, error: string, isAccountCreate: boolean) =>
    trackEvent({
      error,
      attributeType: EVENTS.ATTRIBUTES_TYPE.ERROR,
      attributeValue: { [getAuthAction(provider, isAccountCreate)]: provider?.toLowerCase() },
      ...getPageAndAttributeName(provider, isAccountCreate),
    })

  interface OnBoardUser {
    provider: OauthProvider
  }
  interface SocialSignIn extends OnBoardUser {
    state: string
    code: string
    oneTapToken?: never
  }
  interface OneTapSignIn extends OnBoardUser {
    state?: never
    code?: never
    oneTapToken: string
  }

  const onboardUser = async ({ state, code, provider, oneTapToken }: SocialSignIn | OneTapSignIn) => {
    try {
      setErrMsg('')
      const response = await socialOnboard({
        variables: {
          code,
          provider: provider === 'GOOGLE_ONETAP' ? 'GOOGLE' : provider,
          state,
          oneTapToken,
          dsUserId: getDsUserId(),
        },
      })
      const data = response.data[SOCIAL_ONBOARDING_MUTATION.name]
      const error = getError(data)

      if (error?.code >= 400) {
        trackErrorEvent(provider, error.code, false)
        throw new Error(error.errorMessage)
      }

      handleUserResponse(data)
      applyUserPreferences(data.customer)

      if (!data.accessToken) {
        trackErrorEvent(provider, 'NOT_VERIFIED', data.isCreated)
        setErrMsg(`Your ${provider} account does not have email verified.`)
      } else {
        trackSuccessEvent(provider, data.customer.customerId, data.isCreated)
        if (oneTapToken) {
          window?.location?.reload()
        } else {
          window.location.replace(redirectUrl)
        }

        const redirectingToCheckout = Boolean(matchPath(redirectUrl, BOOKING_DETAILS_ROUTE))

        if (data.isCreated) {
          if (!redirectingToCheckout) {
            setStorageItem(NEW_USER_CONSENT_MODAL, true)
          } else {
            setStorageItem(NEW_ACCOUNT_CREATED_FLAG, true)
          }
        }
      }
    } catch (error) {
      // @ts-ignore
      if (provider) trackErrorEvent(provider, error.message)
      logError(error)
      setErrMsg(t('msg.sorrySomethingWentWrong'))
    }
  }

  return { onboardUser, error: errorMsg }
}

export { useSocialRedirect, useSocialOnboard, usePreAuthLocation }
